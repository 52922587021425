@import '../../../../scss/theme-bootstrap';

.footer-popup {
  &__trigger {
    cursor: pointer;
  }
  &__overlay {
    background: $cr19-bg-dark-transparent;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    .footer-popup__content {
      @include transform(translate(-50%, -50%));
      background-color: $cr19-text-white;
      color: $cr19-text-black;
      position: relative;
      height: auto;
      top: 50%;
      #{$ldirection}: 50%;
      width: 50%;
      max-width: 550px;
      padding: 40px;
      z-index: 10001;
      .footer-popup__close {
        background: url('/sites/clinique/themes/cl_base/img/icon-close.png') no-repeat;
        position: absolute;
        top: 19px;
        #{$rdirection}: 18px;
        height: 21px;
        width: 22px;
        border: none;
        box-sizing: content-box;
      }
      .footer-popup__body {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
